import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
const BlockContent = require("@sanity/block-content-to-react")
const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
}

const CategoryPage = ({ data }) => {
  const page = data && data.page

  return (
    <Layout>
      <SEO title="Categories" />
      <div className="categories-page page-headline">
        <div className="container">
          <div className="section-heading text-center">
            <h1 className="font-weight-bold text-uppercase text-white">
              Categories
            </h1>
          </div>
        </div>
      </div>
      <div className="categories section">
        <div className="container">
          <div className="container">
            <div className="col">
              <h1 className="text-center text-purple">
                <strong>BROWSE BY CATEGORY</strong>
              </h1>
              <p className="text-center text-black-50">
                <strong>
                  We infuse our edibles with a 100% hemp-based, proprietary
                  blend oil. Our oil is extracted using subcritical (low
                  temperature and pressure) CO2. This method is non-toxic and
                  energy efficient. We take pride in knowing our products are
                  the best tasting and made using the best sources and methods.
                </strong>
              </p>
            </div>
            <div className="row">
              <div class="col-md-6 mb-4 mx-auto mt-5">
                <a href="#">
                  <div className="h-100 featured-product-box product1">
                    <div className="featured-txt">
                      <span className="featured-label text-white">
                        FEATURED
                      </span>
                      <h3 className="text-purple font-weight-bold mb-0">
                        Honey
                      </h3>
                      <p className="mb-0 text-black-50">
                        <strong>CBD Infused Honey Sticks- Ginger</strong>
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-md-6 mb-4 mt-5">
                <a href="#">
                  <div className="h-100 featured-product-box product2">
                    <div className="featured-txt">
                      <span className="featured-label text-white">
                        FEATURED
                      </span>
                      <h3 className="text-purple font-weight-bold mb-0">
                        Beverages
                        <br />
                      </h3>
                      <p className="mb-0 text-black-50">
                        <strong>Decaf Espresso Dark Roast Coffee</strong>
                        <br />
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-md-6 mb-4 mt-1">
                <a href="#">
                  <div className="h-100 featured-product-box product3">
                    <div className="featured-txt">
                      <span className="featured-label text-white">
                        FEATURED
                      </span>
                      <h3 className="text-purple font-weight-bold mb-0">
                        Dried Fruit
                        <br />
                      </h3>
                      <p className="mb-0 text-black-50">
                        <strong>Mixed Dried Fruits</strong>
                        <br />
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-6 mb-4 mt-1">
                <a href="#">
                  <div className="h-100 featured-product-box product4">
                    <div className="featured-txt">
                      <span className="featured-label text-white">
                        FEATURED
                      </span>
                      <h3 className="text-purple font-weight-bold mb-0">
                        Dropper Tinctures
                        <br />
                      </h3>
                      <p className="mb-0 text-black-50">
                        <strong>Original Dropper Tinctures</strong>
                        <br />
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-6 mb-4 mt-1">
                <a href="#">
                  <div className="h-100 featured-product-box product5">
                    <div className="featured-txt">
                      <span className="featured-label text-white">
                        FEATURED
                      </span>
                      <h3 className="text-purple font-weight-bold mb-0">
                        Gummies
                        <br />
                      </h3>
                      <p className="mb-0 text-black-50">
                        <strong>Lemonade Rings</strong>
                        <br />
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-6 mb-4 mt-1">
                <a href="#">
                  <div className="h-100 featured-product-box product6">
                    <div className="featured-txt">
                      <span className="featured-label text-white">
                        FEATURED
                      </span>
                      <h3 className="text-purple font-weight-bold mb-0">
                        Honey Gummies
                        <br />
                      </h3>
                      <p className="mb-0 text-black-50">
                        <strong>Cherry Cola with Organic Honey</strong>
                        <br />
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-6 mb-4 mt-1">
                <a href="#">
                  <div className="h-100 featured-product-box product7">
                    <div className="featured-txt">
                      <span className="featured-label text-white">
                        FEATURED
                      </span>
                      <h3 className="text-purple font-weight-bold mb-0">
                        Honey Sticks
                        <br />
                      </h3>
                      <p className="mb-0 text-black-50">
                        <strong>CBD Infused Honey Sticks</strong>
                        <br />
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-6 mb-4 mt-1">
                <a href="#">
                  <div className="h-100 featured-product-box product8">
                    <div className="featured-txt">
                      <span className="featured-label text-white">
                        FEATURED
                      </span>
                      <h3 className="text-purple font-weight-bold mb-0">
                        Honey Tubs
                        <br />
                      </h3>
                      <p className="mb-0 text-black-50">
                        <strong>Honey Tubs- Ginger</strong>
                        <br />
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-6 mb-4 mt-1">
                <a href="#">
                  <div className="h-100 featured-product-box product9">
                    <div className="featured-txt">
                      <span className="featured-label text-white">
                        FEATURED
                      </span>
                      <h3 className="text-purple font-weight-bold mb-0">
                        Popcorn
                        <br />
                      </h3>
                      <p className="mb-0 text-black-50">
                        <strong>Blueberry Popcorn 50mg</strong>
                        <br />
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-6 mb-4 mt-1">
                <a href="#">
                  <div className="h-100 featured-product-box product10">
                    <div className="featured-txt">
                      <span className="featured-label text-white">
                        FEATURED
                      </span>
                      <h3 className="text-purple font-weight-bold mb-0">
                        ALL
                        <br />
                      </h3>
                      <p className="mb-0 text-black-50">
                        <strong>GO TO OUR SHOP</strong>
                        <br />
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="text-center mt-5">
            <a className="button-main" href="#">
              <strong>BUY HERE</strong>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CategoryPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
      id
      title
      _rawBody
    }
  }
`

export default CategoryPage
